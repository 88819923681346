import gql from "graphql-tag";

export const PAGINATE = gql`
  query filterWithPaginateOvertimes($criteria: OvertimeFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateOvertimes(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
        totalPages
      }
      content {
        id
        requestStatus
        requestedDateTime
        overtimeInMinutes
        reason
        description
        workplan {
          date
          assignedShifts {
            name
            beginTime
            endTime
            shiftType
          }
        }
      }
    }
  }
`;

export const WORKPLAN = gql`
  query filterWorkplans($criteria: WorkplanFilterCriteria!) {
    filterWorkplans(criteria: $criteria) {
      id
      date
      assignedShifts {
        name
        beginTime
        endTime
      }
      overtimes {
        id
        requestStatus
      }
    }
  }
`;
