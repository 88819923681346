<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <v-flex xs2>
            <v-menu
              ref="menu1"
              v-model="beginDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedMonth"
                  :label="$t('global.month')"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" type="month" color="primary" @input="beginDateMenu = false"></v-date-picker>
            </v-menu>
          </v-flex>

          <v-spacer />
          <id-button @click="onClickOvertime" class="mr-2">
            <template #icon>
              <v-icon left>mdi-plus</v-icon>
            </template>
            {{ $t("buttons.add_overtime") }}
          </id-button>
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>

        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          class="elevation-1"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          single-expand
        >
          <template #item="row">
            <tr :active="row.selected">
              <td>
                {{ row.item.workplan.date | formattedDate }}
              </td>
              <td>{{ row.item.overtimeInMinutes }} {{ $t("time_duration.minute").toLowerCase() }}</td>
              <td>
                {{ $t(`request_status.${row.item.requestStatus}`) }}
              </td>
              <td>
                {{ row.item.requestedDateTime | formattedDate }}
              </td>
              <td>{{ row.item.reason }}</td>

              <td>
                <v-btn
                  v-if="row.item.description"
                  icon
                  @click.stop.prevent="
                    row.expand(!row.isExpanded);
                    activeTab = 0;
                  "
                >
                  <v-icon v-if="row.isExpanded">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template #expanded-item="{ headers, item }">
            <tr>
              <td :colspan="headers.length" class="pa-0">
                <v-card class="rounded-0" color="grey lighten-3" elevation="0">
                  <v-card-title class="primary--text">{{ $t("global.description") }}</v-card-title>
                  <v-card-text class="px-4">
                    {{ item.description }}
                  </v-card-text>
                </v-card>
              </td>
            </tr>
          </template>
        </v-data-table>
        <transition name="slide-in-left">
          <router-view @success:save="onRefreshData" />
        </transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description shifts Component Logic
   * @date 25.07.2020
   */
  import { PAGINATE } from "./query";

  export default {
    name: "OvertimeRequests",

    data: vm => ({
      beginDateMenu: false,
      date: vm
        .$moment()
        .startOf("month")
        .format("YYYY-MM"),
      table: {
        loading: false,
        data: [],
        criteria: {
          employees: [],
          beginDate: vm
            .$moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: vm
            .$moment()
            .endOf("month")
            .format("YYYY-MM-DD")
        },
        options: {},
        pagination: {
          rowsPerPage: 50,
          sortBy: "workplan.date",
          descending: false
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("time.overtime_request.date"),
            title: vm.$t("time.timesheet.date"),
            value: "",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },
          {
            text: vm.$t("time.overtime_request.overtime_in_minutes"),
            title: vm.$t("time.timesheet.status"),
            value: "",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },
          {
            text: vm.$t("time.overtime_request.request_status"),
            title: vm.$t("time.timesheet.status"),
            value: "",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },
          {
            text: vm.$t("time.overtime_request.request_date"),
            title: vm.$t("time.timesheet.clock_in_title"),
            value: "clockInTimestamp",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },
          {
            text: vm.$t("time.overtime_approval.reason"),
            title: vm.$t("time.timesheet.reason"),
            value: "",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },

          {
            text: vm.$t("global.description"),
            align: "left",
            sortable: false,
            class: ["primary--text"]
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.table.criteria.employees = [this.$store.state.auth.user.id];

          this.fetchItems();
        },
        deep: true
      },
      date: {
        handler(v) {
          this.table.criteria.beginDate = this.$moment(v)
            .startOf("month")
            .format("YYYY-MM-DD");
          this.table.criteria.endDate = this.$moment(v)
            .endOf("month")
            .format("YYYY-MM-DD");
        },
        deep: true
      },
      "table.criteria": {
        handler() {
          this.fetchItems();
        },
        deep: true
      }
    },
    computed: {
      formattedMonth() {
        return this.$moment(this.date).format("MMMM");
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: PAGINATE,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.table.data = data.filterWithPaginateOvertimes.content || [];
              this.table.totalItems = data.filterWithPaginateOvertimes.pageInfo.totalElements || 0;
              this.table.totalPages = data.filterWithPaginateOvertimes.pageInfo.totalPages || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message, "error", true);
          })
          .finally(() => (this.table.loading = false));
      },
      onClickOvertime() {
        this.$router.push({ name: "overtime_request_create" });
      }
    }
  };
</script>

<style scoped></style>
